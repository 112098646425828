import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useDeleteAccount} from './delete-account';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';

export function DangerZonePanel() {
  const deleteAccount = useDeleteAccount();

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.DeleteAccount}
      title={<Trans message="Zona peligrosa" />}
    >
      <DialogTrigger
        type="modal"
        onClose={isConfirmed => {
          if (isConfirmed) {
            deleteAccount.mutate();
          }
        }}
      >
        <Button variant="flat" color="danger">
          <Trans message="Borrar cuenta" />
        </Button>
        <ConfirmationDialog
          isDanger
          title={<Trans message="¿Borrar cuenta?" />}
          body={
            <Trans message="Su cuenta será eliminada de forma inmediata y permanente. Una vez eliminadas, las cuentas no se pueden restaurar." />
          }
          confirm={<Trans message="Eliminar" />}
        />
      </DialogTrigger>
    </AccountSettingsPanel>
  );
}
